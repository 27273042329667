import { useState } from "react";
import "./works.scss";

export default function Works() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const data = [
    {
      id: "1",
      icon: "https://playwright.dev/img/playwright-logo.svg",
      title: "Playwright",
      desc: "Playwright enables reliable end-to-end testing for modern web apps.",
      img: "https://playwright.dev/img/playwright-logo.svg",
    },
    {
      id: "2",
      icon: "https://learn.cypress.io/images/logo/logo.svg",
      title: "Cypress",
      desc: "Cypress is a next generation front end testing tool built for the modern web. ",
      img: "https://learn.cypress.io/images/logo/logo.svg",
    },
    {
      id: "3",
      icon: "https://media-exp1.licdn.com/dms/image/C4E12AQHzW5BtFUcAvg/article-cover_image-shrink_600_2000/0/1635184005998?e=2147483647&v=beta&t=s5IBb5ReY-8w6c08NzaBieLVmY9lE5fhQ53bKXwssOo",
      title: "Selenium",
      desc: "Selenium is an open source umbrella project for a range of tools and libraries aimed at supporting browser automation. ",
      img: "https://media-exp1.licdn.com/dms/image/C4E12AQHzW5BtFUcAvg/article-cover_image-shrink_600_2000/0/1635184005998?e=2147483647&v=beta&t=s5IBb5ReY-8w6c08NzaBieLVmY9lE5fhQ53bKXwssOo",
    },
  ];

  const handleClick = (way) => {
    way === "left"
      ? setCurrentSlide(currentSlide > 0 ? currentSlide - 1 : 2)
      : setCurrentSlide(currentSlide < data.length - 1 ? currentSlide + 1 : 0);
  };

  return (
    <div className="works" id="works">
      <div
        className="slider"
        style={{ transform: `translateX(-${currentSlide * 100}vw)` }}
      >
        {data.map((d) => (
          <div className="container">
            <div className="item">
              <div className="left">
                <div className="leftContainer">
                  <div className="imgContainer">
                    <img src={d.icon} alt="" />
                  </div>
                  <h2>{d.title}</h2>
                  <p>{d.desc}</p>
                  <span>
                    <a
                      href="https://github.com/pradeepku123/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Project-github
                    </a>
                  </span>
                </div>
              </div>
              <div className="right">
                <img src={d.img} alt="" />
              </div>
            </div>
          </div>
        ))}
      </div>
      <img
        src="assets/arrow.png"
        className="arrow left"
        alt=""
        onClick={() => handleClick("left")}
      />
      <img
        src="assets/arrow.png"
        className="arrow right"
        alt=""
        onClick={() => handleClick()}
      />
    </div>
  );
}
