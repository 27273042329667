import "./topbar.scss";
import {
  Person,
  Mail,
  Twitter,
  GitHub,
  LinkedIn,
  PostAdd,
} from "@mui/icons-material";

export default function Topbar({ menuOpen, setMenuOpen }) {
  return (
    <div className={"topbar " + (menuOpen && "active")}>
      <div className="wrapper">
        <div className="left">
          <a href="#intro" className="logo">
            Pradeep.
          </a>
          <div className="itemContainer">
            <Person className="icon" />
            <span>
              <a href="tel:+91 8249777046">+91 8249777046</a>
            </span>
          </div>
          <div className="itemContainer">
            <Mail className="icon" />
            <span>
              <a href="mailto:contact@pradeepkumarqa.in">
                contact@pradeepkumarqa.in
              </a>
            </span>
          </div>
          <div className="itemContainer">
            <Twitter className="icon" />
            <span>
              <a
                href="https://twitter.com/pradeepku123"
                rel="noreferrer"
                target="_blank"
              >
                Twitter
              </a>
            </span>
          </div>
          <div className="itemContainer">
            <GitHub className="icon" />
            <span>
              <a
                href="https://github.com/pradeepku123/"
                rel="noreferrer"
                target="_blank"
              >
                Github
              </a>
            </span>
          </div>
          <div className="itemContainer">
            <LinkedIn className="icon" />
            <span>
              <a
                href="https://www.linkedin.com/in/pradeep-kumar-behera/"
                rel="noreferrer"
                target="_blank"
              >
                LinkedIn
              </a>
            </span>
          </div>
          <div className="itemContainer">
            <PostAdd className="icon" />
            <span>
              <a
                href="https://pradeepkumarqa.medium.com/"
                rel="noreferrer"
                target="_blank"
              >
                Medium blogs
              </a>
            </span>
          </div>
        </div>
        <div className="right">
          <div className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
            <span className="line1"></span>
            <span className="line2"></span>
            <span className="line3"></span>
          </div>
        </div>
      </div>
    </div>
  );
}
